body {
  margin: 0;
  font-family: Georgia, "Nimbus Roman No9 L", "Songti SC", "Noto Serif CJK SC", "Source Han Serif SC", "Source Han Serif CN", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;
  background-color: #d3c8b7;
}


.App {
  margin: 10px 50px;

  .header {
    display: flex;
    align-items: center;


    .lang {
      padding: 5px;
    }

    .active-lang {
      font-weight: bold;
      color: blue;
    }

    .lang:hover {
      cursor: pointer;
      color: blue;
    }
  }

  .container {
    display: flex;

    .layout-text {
      width: 400px;
    }

    .layout-img {
      width: calc(100% - 400px);

      img {
        width: 100%;
      }
    }
  }
}

.header-1 {
  font-size: 100px;
}

.paragraph-1 {
  margin: 1em 0;
  text-indent: 2em;
}

.found-link {
  color: black;
}

@media only screen and (max-width: 1000px) {
  .App {
    margin: 10px;
    .container {
      display: block;

      .layout-text {
        width: 400px;
        padding: 20px;
      }

      .layout-img {
        width: 400px;
  
        img {
          width: 100%;
        }
      }
    }

    
  }

}